<template>
  <div class="productContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">企业基本信息</div>
    <!-- 认证通过 -->
    <div v-if="show">
    <p class="title">企业基本信息</p>
    <div class="card">
        <div class="catalog">
            <p>企业名称</p>
            <p>{{info.enterBaseInfo.enterName}}<br>
            <span v-if="store.state.loginInfo.isOrgAuth === '0'" class="shiming">
                <img src="@/assets/img/userv.png" alt="">
                <span>未认证</span>       
            </span>
            <span v-else class="shiming" @click="contentPop(1)">
                <img src="@/assets/img/userv.png" alt="">
                <span>已认证</span>       
            </span>
            <span v-show="store.state.loginInfo.isOrgAuth === '0'" class="shiming go_shiming" @click="goAuth()">
                <img src="@/assets/img/userv2.png" alt="">
                <span>立即认证</span>     
            </span>
            </p>
        </div>
        <div class="catalog">
            <p>企业性质</p>
            <p>{{ replaceEnterProperty(info.enterBaseInfo.enterProperty)}}</p>
        </div>
        <div class="catalog">
            <p>所属省</p>
            <p>{{info.enterBaseInfo.provinceName}}</p>
        </div>   
        <div class="catalog">
            <p>所属市</p>
            <p>{{info.enterBaseInfo.cityName}}</p>
        </div>
        <div class="catalog">
            <p>所属区</p>
            <p>{{info.enterBaseInfo.districtName}}</p>
        </div>   
        <div class="catalog">
            <p>成立日期</p>
            <p>{{info.enterBaseInfo.foundDate}}</p>
        </div>
        <div class="catalog">
            <p>人数</p>
            <p>{{info.enterBaseInfo.peopleCnt}}</p>
        </div>
        <div class="catalog">
            <p>规模</p>
            <p>{{ replaceScale(info.enterBaseInfo.scale)}}</p>
        </div>
        <div class="catalog">
            <p>所属行业</p>
            <p>{{ replaceIndustry(info.enterBaseInfo.involveIndustry)}}</p>
        </div>   
        <div class="catalog">
            <p>主营业务</p>
            <p>{{info.enterBaseInfo.mainBusiness}}</p>
        </div>
        <div class="catalog">
            <p>法定代表人</p>
            <p>{{info.enterBaseInfo.legalName}}</p>
        </div>   
        <div class="catalog">
            <p>法定代表人证件号码</p>
            <p>{{ replaceCertificate(info.enterBaseInfo.legalCertiType)}}-<br>{{info.enterBaseInfo.legalCertiNum}}</p>
        </div>
         <div class="catalog">
            <p>总经理</p>
            <p>{{info.enterBaseInfo.generalManagerName}}</p>
        </div>   
        <div class="catalog">
            <p>总经理电话号码</p>
            <p>{{info.enterBaseInfo.generalManagerTelNum}}</p>
        </div>
        <div class="catalog">
            <p>财务总监</p>
            <p>{{info.enterBaseInfo.financialOfficerName}}</p>
        </div>   
        <div class="catalog">
            <p>财务总监电话号码</p>
            <p>{{info.enterBaseInfo.financialOfficerTelNum}}</p>
        </div>
        <div class="catalog">
            <p>联系人姓名</p>
            <p>{{info.enterBaseInfo.contactsName}}</p>
        </div>
        <div class="catalog">
            <p>联系人手机号码</p>
            <p>{{info.enterBaseInfo.contactsPhone}}</p>
        </div>
        <div class="catalog">
            <p>联系人电子邮箱</p>
            <p>{{info.enterBaseInfo.contactsEmail}}</p>
        </div>   
        <div class="catalog">
            <p>贷款需求默认联系人姓名</p>
            <p>{{info.enterBaseInfo.creditDefaultContactsName}}</p>
        </div>
        <div class="catalog">
            <p>贷款需求默认联系人电话号码</p>
            <p>{{info.enterBaseInfo.creditDefaultContactsPhone}}</p>
        </div>
    </div>
    <p class="title">企业登记信息</p>
    <div class="card">
        <div class="catalog">
            <p>同一社会信用代码</p>
            <p>{{info.enterRegisterInfo.uniscid}}</p>
        </div>
        <div class="catalog">
            <p>用电客户编号</p>
            <p>{{info.enterRegisterInfo.useElectroAccount}}</p>
        </div>
        <div class="catalog">
            <p>用水户口</p>
            <p>{{info.enterRegisterInfo.useWaterAccount}}</p>
        </div>
        <div class="catalog">
            <p>主要结算银行</p>
            <p>{{info.enterRegisterInfo.principalAccountBank}}</p>
        </div>
        <div class="catalog">
            <p>基本户开户行</p>
            <p>{{info.enterRegisterInfo.basicAccountOpenBank}}</p>
        </div>
        <div class="catalog">
            <p>企业海关注册号</p>
            <p>{{info.enterRegisterInfo.enterCustomsRegNum}}</p>
        </div>
        <div class="catalog">
            <p>排放污染物许可证编号</p>
            <p>{{info.enterRegisterInfo.pollDischPermitNum}}</p>
        </div>
        <div class="catalog">
            <p>危险化学品生产经营许可证证书号</p>
            <p>{{info.enterRegisterInfo.hazardousChemProduBusiLiceNo}}</p>
        </div>   
        <div class="catalog">
            <p>引导机构</p>
            <p>{{info.enterRegisterInfo.guideAgency}}</p>
        </div>
    </div>
    <p class="title">企业经营信息</p>
    <div class="card">
        <div class="catalog">
            <p>上年销售收入（万元）</p>
            <p>{{info.enterBusinessInfo.salesRevenueLastYear}}</p>
        </div>
        <div class="catalog">
            <p>上年利润（万元）</p>
            <p>{{info.enterBusinessInfo.profitLastYear}}</p>
        </div>
        <div class="catalog">
            <p>上年资产额（万元）</p>
            <p>{{info.enterBusinessInfo.assetsLastYear}}</p>
        </div>   
        <div class="catalog">
            <p>上年负债额（万元）</p>
            <p>{{info.enterBusinessInfo.liabilitiesLastYear}}</p>
        </div>
        
    </div>
    <p class="title">上游主要客户</p>
    <div class="card" v-for="item in info.enterBusinessInfo.upMainCustomerList" :key="item">
        <div class="catalog">
            <p>规模</p>
            <p>{{ replaceScale(item.scale)}}</p>
        </div>
        <div class="catalog">
            <p>主要客户名称</p>
            <p>{{item.majorClient}}</p>
        </div>
        <div class="catalog">
            <p>国家或地市</p>
            <p>{{item.addressAreaName}}</p>
        </div>
        <div class="catalog">
            <p>采购额业务占比</p>
            <p>{{item.purchaseAmtRatio}}</p>
        </div>
        <div class="catalog">
            <p>合作年限</p>
            <p>{{item.cooperYears}}</p>
        </div>        
    </div>
    <p class="title">下游主要客户</p>
    <div class="card" v-for="item in info.enterBusinessInfo.downMainCustomerList" :key="item">
        <div class="catalog">
            <p>规模</p>
            <p>{{ replaceScale(item.scale)}}</p>
        </div>
        <div class="catalog">
            <p>主要客户名称</p>
            <p>{{item.majorClient}}</p>
        </div>
        <div class="catalog">
            <p>国家或地市</p>
            <p>{{item.addressAreaName}}</p>
        </div>
        <div class="catalog">
            <p>采购额业务占比</p>
            <p>{{item.purchaseAmtRatio}}</p>
        </div>
        <div class="catalog">
            <p>合作年限</p>
            <p>{{item.cooperYears}}</p>
        </div>        
    </div>
    <div style="text-align: center;padding: 4vw 0;">
        <router-link to="/editCompanyInfo" class="edit">修改资料</router-link>
    </div>
    </div>

     <!-- 认证情况 -->
    <van-popup round v-model:show="showContent">
        <div class="pop">
            <div class="pop-header">认证情况</div>
            <div class="pop-confirm">
                <div class="content">
                    <p>认证银行：</p>
                    <p>{{info.enterAuthInfo.bankName}}</p>
                </div>
                <div class="content">
                    <p>认证时间：</p>
                    <p>{{info.enterAuthInfo.authTime}}</p>
                </div>
                <div class="content">
                    <p>认证方式：</p>
                    <p>{{ checkAuthType(info.enterAuthInfo.authType)}}</p>
                </div>
            </div>
            <div class="pop-footer">
                <div class="next" @click="contentPop(0)">确认</div>
            </div>
        </div>
    </van-popup>
  </div>
</template>
<script>
import { ref,onMounted } from 'vue';
import { apiGetEnterInfo,replaceEnterProperty,replaceIndustry,replaceScale,replaceCertificate } from '../../api/axios';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
   setup() {
    let router = useRouter();
    let store = useStore();
    const showContent = ref(false);
    const show = ref(false);
    const info = ref({});
    const contentPop = (i) =>{
        if(i){
            showContent.value = true;
        }else {
            showContent.value = false;
        }
        
    };
    const checkAuthType = (str) => {
          let x = str;
          switch (str)
              { 
                case '1':x="企业现场办理贷款认证"; 
                break; 
                case '2':x="企业基本户开户行认证"; 
                break; 
                case '3':x="企业工商信息+法人人脸识别"; 
                break; 
                case '4':x="企业工商信息+法人银行卡四要素";
                break; 
                case '5':x="企业工商信息+企业小额打款"; 
                break; 
                case '6':x="企业网银Ukey认证";
                break;
                case '7':x="线下认证";
              }
          return x;
    };
    const goAuth = () => {
        router.push({
            name: 'auth'
        })
    };
    onMounted(()=>{
        apiGetEnterInfo({}).then(res => {               
        if(res.code == '1'){
          info.value = res.info;  
          show.value = true;
         }
         else {
           Toast(res.msg);
         }
    });
    });
    const goBack = () =>{
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.back.postMessage(null);
          } catch (e) {
            alert('跳转返回ios失败');
          }
        }else {
            router.push({
                name: 'User'
            })
        }
    }
    return {
        store,
        info,
        show,
        showContent,
        checkAuthType,
        contentPop,
        goAuth,
        replaceEnterProperty,
        replaceIndustry,
        replaceScale,
        replaceCertificate,
        goBack
     };
  },

}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    .card {
        padding: 2vw 4vw;
        .catalog {
            border-bottom: 1px solid rgba(0,0,0,.1);
            padding: 3vw 0;
            display: flex;
            p:first-child {
                flex:1;
                color: #666666;
                font-size: 4vw;
            }
            p:last-child {
                flex:2;
                font-size: 4vw;
                padding-left: 4vw;
            }
            .shiming {
                margin-top: 1vw;
                padding: 0 2vw;
                background: linear-gradient(to right, #29d8b5, #5ac6ea);
                height: 6vw;
                border-radius: 4vw;
                display: inline-flex;
                align-items: center;
                img {
                    height: 4vw;
                }
                span{
                    font-size: 3.3vw;
                    color: #fff;
                    padding-left: 1vw;
                    white-space: nowrap;
                }
            }
            .go_shiming {
                background: linear-gradient(to right, #fdb012, #ff9900);
                margin-left: 2vw;
            }
        }
        .catalog:last-child {
            border-bottom: 0;
        }
    }
    .edit {
        display: inline-block;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 4.5vw;
        padding: 1vw 4vw;
        border-radius: 5vw;
    }
    .pop {
        padding: 4vw;
        width: 70vw;        
        .pop-header {
            font-size: 5vw;
            font-weight: 600;
            text-align: center;
            margin-bottom: 4vw;
        }
        .pop-confirm {
            .content {
                padding: 3vw 2vw;
                border-bottom: 1px solid #f5f5f5;
                display: flex;
                p{
                    font-size: 4vw;
                }
                p:first-child {
                    width: 34%;
                }
                p:last-child{
                    width: 66%;
                }
            }
            
             
        }

        .pop-footer {
            text-align: center;
            padding-top: 4vw;
            .next {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                margin-left: 4vw;
                width: 22vw;
            }
        }
    }
    
}
</style>
